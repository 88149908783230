html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  overflow: auto;
}

.no-scroll {
    overflow: hidden !important;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  scroll-behavior: smooth;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0) !important;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0) !important;
  border-radius: 10px !important;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px !important;
}

/* react-file-viewer custom styles */

/* to center pdfs */
.pdf-canvas > canvas {
  width: 100%;
}

/* custom loaders */
.pg-viewer-wrapper .loading-container .loading {
  border: 4px solid #e4e4e4;
  border-top: 4px solid #ed702e;
  border-radius: 50%;
  animation: rotating 2s linear infinite;
  display: inline-block;
  background-image: none !important;
}

/*
  react-day-picker styles
*/

.rdp {
  --rdp-accent-color: #ed702e;
  --rdp-background-color: #fff1e0;
}

@font-face {
  font-family: 'Clearface';
  src: local('Clearface'), url('./assets/fonts/ClearfacePro-Thin.otf');
}
@font-face {
  font-family: 'Clearface-Italic';
  src: url('./assets/fonts/ITC-Clearface-Std-Italic.otf');
  /* You can include other font properties here if needed */
}
@font-face {
    font-family: "Futura";
    src: url("./assets/fonts/futura/book.otf");
    font-weight: normal;
  }
  @font-face {
    font-family: "Futura";
    font-style: italic;
    src: url("./assets/fonts/futura/bookItalic.otf");
    font-weight: normal;
  }
  @font-face {
    font-family: "Futura";
    src: url("./assets/fonts/futura/medium.otf");
    font-weight: 500;
  }
  @font-face {
    font-family: "Futura";
    font-style: italic;
    src: url("./assets/fonts/futura/mediumItalic.otf");
    font-weight: 500;
  }
  @font-face {
    font-family: "Futura";
    src: url("./assets/fonts/futura/demibold.otf");
    font-weight: 700;
  }
  @font-face {
    font-family: "Futura";
    font-style: italic;
    src: url("./assets/fonts/futura/demiboldItalic.otf");
    font-weight: 700;
  }

/* mapbox navigation control */
.mapboxgl-ctrl-top-right {
  z-index: 800;
}

.blurb-background {
  width: 515px;
  height: 108px;
  background-image: url('data:image/svg+xml;utf8, <svg width="515" height="108" viewBox="0 0 515 108" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M481.588 1C480.819 -0.333332 478.894 -0.333334 478.124 0.999999L470.619 14H4C1.79086 14 0 15.7909 0 18V104C0 106.209 1.79085 108 3.99999 108H511C513.209 108 515 106.209 515 104V18C515 15.7909 513.209 14 511 14H489.094L481.588 1Z" fill="white"/></svg>');
  background-repeat: no-repeat;
  background-size: cover;
  /* Add any additional styles you need */
}

.tour-mask {
  border-radius: 20px;
}